
































































































































import Vue from "vue";
import CarreraService from "@/api/CarreraService";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import CantonService from "@/api/pedido/CantonService";
//import moment from "moment";
export default Vue.extend({
  components: {
    DateTimePicker
  },
  props: ["dialog", "dato"],
  data() {
    return {
      modalF1: false,
      modalF2: false,
      modalF3: false,
      modalF4: false,
      editedIndex: -1,
      editedItem: {
        nombre: "",
        fechaInicioEvento: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        fechaFinEvento: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        fechaInicioInscripcion: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        fechaFinInscripcion: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),

        ciudad: "",
        lugar: "",

        estado: "",
        estadoPersonalizacion: "",
        tipoParticipante: "",
        modalidad: "",

        hashtag: "",
        cupos: 0
      },
      estadoOptions: [
        { text: "Borrador", value: "1" },
        { text: "Publicado", value: "2" },
        { text: "Privado", value: "3" }
      ],
      estadoPersonalizacionOptions: [
        { text: "Activo", value: "1" },
        { text: "Inactivo", value: "2" }
      ],
      tipoParticipanteOptions: [
        { text: "Hombre", value: "M" },
        { text: "Mujer", value: "F" },
        { text: "Mixto", value: "T" }
      ],
      modalidadOptions: [
        { text: "Virtual", value: "V" },
        { text: "Presencial", value: "P" },
        { text: "Presencial durante COVID", value: "PCV" }
      ],
      cantones: [] as any
    };
  },
  created() {
    this.loadCantones();
    this.editedItem = { ...this.dato };
    //console.log(this.editedItem);
    /*
    if (
      this.editedItem.fechaInicioEvento &&
      this.editedItem.fechaInicioEvento.length > 0
    ) {
      this.editedItem.fechaInicioEvento = moment(
        new Date(this.editedItem.fechaInicioEvento)
      ).format("YYYY-MM-DD");
      this.editedItem.fechaFinEvento = moment(
        new Date(this.editedItem.fechaFinEvento)
      ).format("YYYY-MM-DD");
      this.editedItem.fechaFinInscripcion = moment(
        new Date(this.editedItem.fechaFinInscripcion)
      ).format("YYYY-MM-DD");
    }
    */
  },
  methods: {
    loadCantones() {
      this.cantones = CantonService.getCantones();
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      //console.log(this.editedItem);
      //const newDto = this.editedItem;
      /*
      this.editedItem.cupos = parseInt(String(this.editedItem.cupos));
      this.editedItem.horaInicioEvento = parseFloat(
        String(this.editedItem.horaInicioEvento)
      );
      this.editedItem.horaFinEvento = parseFloat(
        String(this.editedItem.horaFinEvento)
      );
      this.editedItem.horaFinInscripcion = parseFloat(
        String(this.editedItem.horaFinInscripcion)
      );
      */

      CarreraService.save(this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Carrera",
            text: "Creada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Carrera",
            text: "No se pudo crear"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
