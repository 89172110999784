

























































import CarreraAdd from "@/components/admin/evento/CarreraAdd.vue";
import Vue from "vue";
import CarreraService, { CarreraCreateDto } from "@/api/CarreraService";

export default Vue.extend({
  components: {
    CarreraAdd
  },
  metaInfo: {
    title: "Carreras"
  },
  data() {
    return {
      dialog: false,
      dato: {
        nombre: "",

        fechaInicioEvento: "",
        fechaFinEvento: "",

        //horaInicioEvento: 12.0,
        //horaFinEvento: 12.0,

        fechaInicioInscripcion: "",
        fechaFinInscripcion: "",
        //horaInicioInscripcion: 12.0,
        //horaFinInscripcion: 12.0,

        ciudad: "",
        lugar: "",

        estado: "",
        tipoParticipante: "",
        modalidad: "",

        hashtag: "",
        cupos: 0
      } as CarreraCreateDto,
      headers: [
        //{ text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" }, // disp
        { text: "Ciudad", value: "ciudad" },
        { text: "Cupos", value: "getCuposDisponibles" },
        { text: "Inicio Evento", value: "FechaInicioEvento" },
        { text: "Fin Evento", value: "FechaFinEvento" },
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as CarreraCreateDto[]
    };
  },
  methods: {
    toEditor(id: number) {
      this.$router.push(`carrera/editor/${id}`);
    },
    setDefaultItem() {
      const newDto = {
        nombre: "",
        fechaInicioEvento: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          7
        ),
        fechaFinEvento: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          8
        ),
        fechaInicioInscripcion: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          7
        ),
        fechaFinInscripcion: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          8
        ),
        horaInicioEvento: 12.0,
        horaFinEvento: 12.0,
        horaInicioInscripcion: 12.0,
        horaFinInscripcion: 12.0,

        ciudad: "",
        lugar: "",

        estado: "",
        tipoParticipante: "",
        modalidad: "",
        estadoPersonalizacion: "",

        hashtag: "",
        cupos: 0
      };
      this.dato = newDto;
    },
    editItem(item: CarreraCreateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      CarreraService.getAll()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Carreras",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
